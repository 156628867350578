import { createApp } from 'vue'
import './font/style.css';
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import App from './App.vue'
import router from './router/index.js'
import zhCn from 'element-plus/dist/locale/zh-cn.mjs'
import * as ElIconList from '@element-plus/icons-vue'
import loadmore from '@/utils/loadmore'

const app = createApp(App)

app.directive(loadmore.name, loadmore.componentUpdated)

for (const name in ElIconList) {
  app.component(name, ElIconList[name])
}

app.use(ElementPlus,{ locale: zhCn })

app.use(router).mount('#app')