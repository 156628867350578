export const loginRouter = {
  path: '/login',
  name: 'login',
  meta: {
    title: '登录'
  },
  component: () => import('../views/login.vue')
}
// 导航显示的路由
export const unattendedRouter = {
  path: '/work',
  name: '',
  component: () => import ('../views/menu.vue'),
  children: [
    { path:'list',name:'list',icon:'iconfont icon-TPFX',meta:{title:'工时管理'},component: () => import('../views/list.vue')},
    { path:'details',name:'details',icon:'iconfont icon-XTPZ',meta:{title:'工时录入'},component: () => import('../views/details.vue')},
  ]
}

export const routers = [
  loginRouter,
  unattendedRouter,
]
