import { createRouter, createWebHistory } from 'vue-router'
import { routers } from './router';

export const router = createRouter({
  history: createWebHistory(),
  routes: routers
})
router.beforeEach((to, from, next) => {
  if(typeof(localStorage.userData) == "undefined" && to.name !== 'login'){
    next({ name: 'login' });
  } else if(to.name === 'login') {
    if(typeof(localStorage.userData) != "undefined") {
      next({ name: 'details' })
    } else {
      next();
    }
  } else if(to.path === '/'){
    next({ name: 'login' })
  } else {
    // if(JSON.parse(localStorage.userData).iotRole == 2 && to.name == 'list') {
    //   next({ name: 'details' })
    // } else {
    //   next()
    // }
    next()
  }
})
router.afterEach(() => {
  window.scrollTo(0, 0);
});

export default router