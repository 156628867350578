// 设置默认溢出显示数量
var spillDataNum = 20;

// 设置隐藏函数
var timeout = false;
let setRowDisableNone = function (topNum, showRowNum, binding) {
  if (timeout) {
    clearTimeout(timeout);
  }
  timeout = setTimeout(() => {
    binding.value.call(null, topNum, topNum + showRowNum + spillDataNum);
  });
};

export default {
  name: "loadmore",
  componentUpdated: function (el, binding, vnode, oldVnode) {
    const dataSize = vnode.props["data-size"];
    let oldDataSize = 0;
    if (oldVnode) {
      oldDataSize = oldVnode.props["data-size"];
    }
    if (dataSize === oldDataSize) {
      return;
    }
    const selectWrap = el.querySelector(".el-scrollbar__wrap");
    const selectTbody = selectWrap.querySelector("table tbody");
    const selectRow = selectWrap.querySelector("table tr");
    if (!selectRow) {
      return;
    }
    const rowHeight = 41; // 表格行高, 必须设置正确
    let showRowNum = 18; // 页面显示条数; 根据实际高度测算: 目前页面显示18条, 数量不对会导致页面数据显示不全就不能滚动了;
    const createElementTR = document.createElement("tr");
    let createElementTRHeight =
      (dataSize - showRowNum - spillDataNum) * rowHeight;
    createElementTR.setAttribute(
      "style",
      `height: ${createElementTRHeight}px;`
    );
    selectTbody.append(createElementTR);
    // 监听滚动后事件
    selectWrap.addEventListener("scroll", function () {
      let topPx = this.scrollTop - spillDataNum * rowHeight;
      let topNum = Math.round(topPx / rowHeight);
      let minTopNum = dataSize - spillDataNum - showRowNum;
      if (topNum > minTopNum) {
        topNum = minTopNum;
      }
      if (topNum < 0) {
        topNum = 0;
        topPx = 0;
      }
      selectTbody.setAttribute("style", `transform: translateY(${topPx}px)`);
      createElementTR.setAttribute(
        "style",
        `height: ${
          createElementTRHeight - topPx > 0 ? createElementTRHeight - topPx : 0
        }px;`
      );
      setRowDisableNone(topNum, showRowNum, binding);
    });
  },
};
