<template>
  <router-view ></router-view>
</template>

<style lang="scss">
#app {
  height: 100%;
  width: 100%;
  touch-action: none;
  font-family: PingFang SC;
  margin: 0;
  padding: 0;
}
.el-cascader-menu:last-child {
  width: 400px;
}
.el-cascader-menu:first-child {
  width: 200px;
}
.el-cascader-node__label {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  line-height: 14px;
  white-space: pre-wrap
}
.el-table th.gutter{
  display: table-cell!important;
}
.el-table__header,.el-table__footer {
  margin:0 auto; 
}
.el-dialog {
  margin-top: 0 !important;
  top: 50%;
  transform: translateY(-50%);
  ::-webkit-scrollbar {
    width: 6px;
  }
  ::-webkit-scrollbar-track {
    -webkit-box-shadow:inset006pxrgba(0,0,0,0.3);
    border-radius:6px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius:6px;
    background:rgba(0,0,0,0.1);
    -webkit-box-shadow:inset006pxrgba(0,0,0,0.5);
  }
  ::-webkit-scrollbar-thumb:window-inactive {
    background:rgba(0,0,0,0.1);
  }
}
</style>
